<template>
  <div id="admin-article-post" class="admin-contents">
    <div class="admin-contents-title admin-article-post-title">文章编辑</div>
    <div class="admin-contents-wrap admin-article-post-info-wrap">
      <el-form
        :model="article"
        :label-position="'left'"
        :label-width="'80px'"
        class="admin-article-post-info-form">
        <el-form-item label="标题">
          <el-input v-model="article.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <el-input
            type="textarea"
            :autosize="{ minRows: 1 }"
            v-model="article.subMessage"
            autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-select
            size="medium"
            v-model="article.category"
            @focus="categoryHandleSelect"
            filterable
            clearable
            allow-create
            placeholder="分类">
            <el-option
              v-for="item in categories"
              :key="item.categoryId"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签">
          <el-select
            class="input-new-tag"
            v-model="article.tags"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="标签">
            <el-option
              v-for="item in tags"
              :key="item.tagId"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面">
          <div class="article-cover-choose" @click="coverImageChooseHandle">
            <div v-if="!article.coverImage" class="article-cover-tip">请选择封面图片</div>
            <img v-else :src="article.coverImage" alt="封面图片">
          </div>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-input
            class="article-time"
            placeholder="YYYY-MM-DD HH:MM:SS"
            v-model="article.publishTime"
            autocomplete="off"
            width="200">
          </el-input>
        </el-form-item>
        <el-form-item label="修改时间">
          <el-input
            class="article-time"
            placeholder="YYYY-MM-DD HH:MM:SS"
            v-model="article.modifiedTime"
            autocomplete="off">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="admin-article-post-editor-wrap">
      <label class="admin-article-post-editor-label">编辑正文</label>
      <mavon-editor
        class="admin-article-post-editor"
        ref="articleEditor"
        v-model="article.contents"
        @save="submit(false)"
        placeholder="# 一级目录"
      />
      <el-tooltip effect="dark" content="媒体库" placement="top">
        <div
          class="mediaChooseButton"
          @click="mediaChooseClick"
          ref="mediaChooseButton">
          <i class="el-icon-picture"></i>
        </div>
      </el-tooltip>
    </div>
    <div class="admin-article-post-botton">
      <div class="admin-article-post-botton-group">
        <el-button type="warning" plain @click="submit(false)">保存草稿</el-button>
        <el-button type="danger" plain @click="submit(true)">发布文章</el-button>
      </div>
    </div>
    <el-dialog
      title="封面选择"
      width="1100px"
      :visible.sync="coverImageChooseVisible">
      <div class="cover-image-wrap">
        <div class="cover-image-choose">
          <el-date-picker
            size="small"
            v-model="coverTime"
            value-format="yyyy-MM-dd"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
          <el-button
            size="small"
            type="primary"
            @click="queryCoverData"
            icon="el-icon-search">
            搜索
          </el-button>
        </div>
        <div class="cover-image">
          <div
            class="cover-image-item"
            v-for="(item, index) in coverData"
            :key="index"
            @dblclick="setCoverImage(item.url)">
            <img v-lazy="item.url" :alt="item.name">
          </div>
        </div>
        <div class="cover-image-pagination">
          <el-pagination
            @current-change="handleCoverCurrentChange"
            :current-page="coverCurrentPage"
            layout="prev, pager, next"
            :total="coverTotal">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="媒体选择"
      width="1100px"
      :visible.sync="mediaChooseVisible">
      <div class="media-wrap">
        <div class="media-choose">
          <el-select
            v-model="mediaTypeValue"
            clearable
            size="small"
            placeholder="默认全部媒体类型">
            <el-option
              v-for="item in mediaTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-date-picker
            size="small"
            v-model="mediaTime"
            value-format="yyyy-MM-dd"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
          <el-button
            size="small"
            type="primary"
            @click="queryMediaData"
            icon="el-icon-search">
            搜索
          </el-button>
        </div>
        <div class="media">
          <div
            class="media-item"
            v-for="(item, index) in mediaData"
            :key="index"
            @dblclick="chooseMedia(item)">
            <img
              v-if="item.mediaType === 1 || item.mediaType === 2"
              v-lazy="item.url"
              :alt="item.name">
            <video controls v-else>
              <source :src="item.url">
            </video>
          </div>
        </div>
        <div class="media-pagination">
          <el-pagination
            @current-change="handleMediaCurrentChange"
            :current-page="mediaCurrentPage"
            layout="prev, pager, next"
            :total="mediaTotal">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { media, blog } from '@/utils/config'

export default {
  name: 'admin-article-post',
  beforeRouteLeave: function (to, from, next) {
    if (!this.isSaveArticle()) {
      const answer = window.confirm('您有改动未保存，是否放弃修改?')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    // 添加markdown自定义按钮
    const md = this.$refs.articleEditor
    const toolbar_left = md.$refs.toolbar_left
    const mediaChooseButton = this.$refs.mediaChooseButton
    toolbar_left.$el.append(mediaChooseButton)
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  data () {
    return {
      articleId: this.$route.query.articleId || '',
      // originArticle 用于判断文章是否保存，和article比较
      originArticle: {
        title: '',
        subMessage: '',
        coverImage: '',
        contents: '',
        category: '',
        tags: []
      },
      article: {
        title: '',
        subMessage: '',
        coverImage: '',
        contents: '',
        category: '',
        tags: []
      },
      categories: [],
      tags: [],
      tagInputValue: '',
      // markdown媒体查询
      mediaChooseVisible: false,
      mediaTypeValue: null,
      mediaTime: [],
      mediaTypeOptions: media.MEDIA_TYPE,
      mediaData: [],
      mediaCurrentPage: 1,
      mediaTotal: 0,
      // 封面查询
      coverImageChooseVisible: false,
      coverData: [],
      coverCurrentPage: 1,
      coverTime: '',
      coverTotal: 0,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  created () {
    this.getArticle()
    this.getAllCategories()
    this.getAllTags()
  },
  methods: {
    getAllCategories () {
      this.$auth_http({
        url: this.$api.ADMIN_CATEGORIES_URL,
        method: 'GET'
      }).then(response => {
        for (const item of response.data.result) {
          this.categories.push(item)
        }
      }).catch(error => {
        this.$message.error('获取分类失败：' + error.toString() + '，请重试')
      })
    },
    getAllTags () {
      this.$auth_http({
        url: this.$api.ADMIN_TAGS_URL,
        method: 'GET'
      }).then(response => {
        for (const item of response.data.result) {
          this.tags.push(item)
        }
      }).catch(error => {
        this.$message.error('获取标签失败：' + error.toString() + '，请重试')
      })
    },
    getArticle () {
      if (this.articleId) {
        this.$auth_http({
          url: this.$api.ADMIN_ARTICLE_URL,
          method: 'GET',
          params: { articleId: this.articleId }
        }).then(response => {
          if (response.status === 'success') {
            this.article = JSON.parse(JSON.stringify(response.data.article))
            this.copyArticle()
          } else {
            this.$message.error('文章获取失败，' + response.message + '，请刷新')
          }
        }).catch(error => {
          this.$message.error('文章获取失败，' + error.toString() + '，请重试')
        })
      }
    },
    submit (submitType) {
      // 去除空格
      for (const key in this.article) {
        if (typeof (this.article[key]) === 'string' && this.article[key]) {
          this.article[key] = this.article[key].trim()
        }
      }
      // 设置默认背景图
      this.article.coverImage = this.article.coverImage || blog.ArticleDefautBackground
      // 提交参数
      const articleSubmitData = JSON.parse(JSON.stringify(this.article))
      if (this.articleId) articleSubmitData.articleId = this.articleId
      if (submitType) {
        // 发表
        for (const key in this.article) {
          // 判断是否为空
          if ((typeof (this.article[key]) === 'string' && !this.article[key]) ||
            (this.article[key] instanceof Array && this.article[key].length === 0)) {
            this.$message({
              message: '文章信息不完善！',
              type: 'warning'
            })
            return false
          }
        }
        articleSubmitData.status = 1
      } else {
        // 草稿
        if (!this.article.title) {
          this.$message({
            message: '请填写文章标题！',
            type: 'warning'
          })
          return false
        }
        articleSubmitData.status = 0
      }
      // 处理tags，json序列化
      articleSubmitData.tags = JSON.stringify(this.article.tags)
      // 提交
      this.$auth_http({
        url: this.$api.ADMIN_ARTICLE_URL,
        method: 'POST',
        data: articleSubmitData
      }).then(response => {
        if (response.status === 'success') {
          this.$message({
            message: '文章保存成功！',
            type: 'success'
          })
          this.copyArticle()
          // 保存articleId
          if (!this.articleId) {
            this.articleId = response.data.articleId
            this.$router.push({
              name: 'AdminArticleEditor',
              query: {
                articleId: response.data.articleId
              }
            })
          }
        } else {
          this.$message.error(response.message)
        }
      }).catch(error => {
        this.$message.error('文章保存失败，' + error.toString() + '，请重试')
      })
    },
    categoryHandleSelect (item) {
      this.article.category = item.value
    },
    coverImageChooseHandle () {
      this.coverImageChooseVisible = true
      this.getCover()
    },
    getCover () {
      this.coverData = []
      const queryData = {
        page: this.coverCurrentPage,
        pageSize: 8,
        mediaType: 1
      }
      if (this.coverTime) {
        queryData.start = this.coverTime[0]
        queryData.end = this.coverTime[1]
      }
      this.$auth_http({
        url: this.$api.ADMIN_MEDIA_URL,
        method: 'GET',
        params: queryData
      }).then(response => {
        this.coverTotal = response.data.total
        for (const item of response.data.result) {
          this.coverData.push({
            name: item.name.substring(item.name.lastIndexOf('/') + 1),
            url: item.url,
            timestamp: item.timestamp,
            mediaType: media.MEDIA_TYPE_NAME[item.mediaType],
            mid: item.mid
          })
        }
      }).catch(error => {
        this.$message.error('媒体信息获取失败，' + error.toString() + '，请重试')
      })
    },
    handleCoverCurrentChange (val) {
      this.coverCurrentPage = val
      this.getCover()
    },
    queryCoverData () {
      this.coverCurrentPage = 1
      this.getCover()
    },
    setCoverImage (url) {
      this.article.coverImage = url
      this.coverImageChooseVisible = false
    },
    getMedia () {
      this.mediaData = []
      const queryData = {
        page: this.mediaCurrentPage,
        pageSize: 8
      }
      if (this.mediaTypeValue) {
        queryData.mediaType = this.mediaTypeValue
      }
      if (this.mediaTime) {
        queryData.start = this.mediaTime[0]
        queryData.end = this.mediaTime[1]
      }
      this.$auth_http({
        url: this.$api.ADMIN_MEDIA_URL,
        method: 'GET',
        params: queryData
      }).then(response => {
        this.mediaTotal = response.data.total
        for (const item of response.data.result) {
          this.mediaData.push({
            name: item.name.substring(item.name.lastIndexOf('/') + 1),
            url: item.url,
            timestamp: item.timestamp,
            mediaType: item.mediaType,
            mid: item.mid
          })
        }
      }).catch(error => {
        this.$message.error('媒体信息获取失败，' + error.toString() + '，请重试')
      })
    },
    mediaChooseClick () {
      this.mediaChooseVisible = true
      this.getMedia()
    },
    handleMediaCurrentChange (val) {
      this.mediaCurrentPage = val
      this.getMedia()
    },
    chooseMedia (item) {
      if (item.mediaType === 1 || item.mediaType === 2) {
        this.article.contents += `![](${item.url})`
      } else {
        this.article.contents += `<video controls><source src="${item.url}"></video>`
      }
      this.mediaChooseVisible = false
    },
    queryMediaData () {
      this.mediaCurrentPage = 1
      this.getMedia()
    },
    copyArticle () {
      this.originArticle = JSON.parse(JSON.stringify(this.article))
    },
    isSaveArticle () {
      return JSON.stringify(this.article) === JSON.stringify(this.originArticle)
    },
    beforeunloadHandler (e) {
      if (!this.isSaveArticle()) {
        e = e || window.event
        // 兼容IE8和Firefox 4之前的版本
        if (e) {
          e.returnValue = '关闭提示'
        }
        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return ('关闭提示')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.admin-wrap
  .admin-body
    .admin-body-col-container
      height: calc(100vh - 122px)

#admin-article-post
  .admin-article-post-editor-wrap
    margin: 0 20px 80px 20px
    .admin-article-post-editor-label
      color: #606266
      line-height: 40px
      font-size: 14px
    .admin-article-post-editor
      min-height: calc(100vh - 240px)
  .admin-article-post-info-wrap
    padding: 0 20px
    .admin-article-post-info-form
      max-width: 800px
      .cover-image-url-input
        margin-bottom: 20px
      .article-cover-choose
        width: 210px
        height: 118px
        padding: 5px 2px
        border: #eee solid 1px
        display:flex
        align-items: center
        cursor: pointer
        transition: all 0.3s
        border-radius: 3px
        box-shadow: 0 0 5px 0 rgba(38, 42, 48, .1)
        &:hover
          border: #4fbaff solid 1px
        .article-cover-tip
          font-size: 12px
          font-weight: 600
          color: #b4b4b4
          margin: 0 auto
        img
          max-height: 100%
          max-width: 100%
          margin: 0 auto
      .el-tag
        margin-right: 10px
      .button-new-tag
        height: 32px
        line-height: 30px
        padding-top: 0
        padding-bottom: 0
      .input-new-tag
        width: 100%
      .article-time
        width: 200px
  .admin-article-post-botton
    height: 60px
    width: 100%
    padding: 0 20px
    position: fixed
    bottom: 0
    z-index: 1501
    border-top: 1px solid rgba(38, 42, 48, .1)
    background-color: #FFF
    .admin-article-post-botton-group
      height: 100%
      padding: 10px 0
  .cover-image-wrap
    width: 100%
    .cover-image-choose
      margin-bottom: 20px
      display: inline-flex
      .el-button
        margin-left: 10px
    .cover-image
      width: 100%
      display: flex
      flex-wrap: wrap
      .cover-image-item
        width: 250px
        height: 200px
        display:flex
        align-items: center
        border: solid 1px #eee
        margin-right: 20px
        margin-bottom: 30px
        padding: 5px
        cursor: pointer
        transition: all 0.3s
        border-radius: 3px
        box-shadow: 0 0 5px 0 rgba(38, 42, 48, .1)
        &:hover
          border: #4fbaff solid 1px
        &:nth-child(4n)
          margin-right: 0
        img
          max-height: 100%
          max-width: 100%
          margin: 0 auto
    .cover-image-pagination
      width: 100%
      text-align: center
  .media-wrap
    width: 100%
    .media-choose
      margin-bottom: 20px
      display: inline-flex
      .el-date-editor
        margin-left: 10px
      .el-button
        margin-left: 10px
    .media
      width: 100%
      display: flex
      flex-wrap: wrap
      .media-item
        width: 250px
        height: 200px
        display:flex
        align-items: center
        border: solid 1px #eee
        margin-right: 20px
        margin-bottom: 30px
        padding: 5px
        cursor: pointer
        transition: all 0.3s
        border-radius: 3px
        box-shadow: 0 0 5px 0 rgba(38, 42, 48, .1)
        &:hover
          border: #4fbaff solid 1px
        &:nth-child(4n)
          margin-right: 0
        img
          max-height: 100%
          max-width: 100%
          margin: 0 auto
        video
          max-height: 100%
          max-width: 100%
          margin: 0 auto
    .media-pagination
      width: 100%
      text-align: center

.mediaChooseButton
  display: inline-block
  align-items: center
  cursor: pointer
  height: 28px
  width: 28px
  margin: 6px 0 5px 0
  font-size: 15px
  padding: 6px
  color: #409EFF
  border-radius: 5px
  text-align: center
  line-height: 1
  vertical-align: middle
  transition: all 0.3s
  &:hover
    color: rgba(0, 0, 0, 0.8)
    background: #e5e5e5
</style>
